import { Button } from "@mui/material";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import "./LoginScreen.css";
import "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../firebaseConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const LoginScreen: React.FC = () => {
  const { loginError } = useSelector((state: RootState) => state.auth);

  const handleGoogleSignin = async () => {
    await signInWithPopup(auth, provider).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      console.log(errorCode, errorMessage, email, credential);
    });
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <h1>Let's Get Started</h1>

        {loginError ? (
          <h5 className="error-text">
            Opps! Something went wrong. Please try again
          </h5>
        ) : (
          <h5>Sign in to continue</h5>
        )}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FcGoogle />}
          onClick={handleGoogleSignin}
        >
          Signin with Google
        </Button>
      </div>
    </div>
  );
};

export default LoginScreen;
