import React, { useState } from "react";
import BasicModal from "../../components/BasicModal";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import "./createTable.css";
import { TCreateTableRequest } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import FileUpload from "../../components/FileUpload/FileUpload";
import { extractCsvString } from "../../common/utils";
import { RootState } from "../../store";
import { createTable } from "../../apis/services";
import { addTable } from "../../store/selectedAppReducer";

type TProps = {
  show: boolean;
  handleClose: () => void;
};

const INITIAL_ERROR = {
  name: false,
  tableName: false,
  isRootTable: false,
  csvInputString: false,
};
const INITIAL_DATA = {
  name: "",
  tableName: "",
  isRootTable: false,
  csvInputString: "",
};

const CreateTable: React.FC<TProps> = (props) => {
  const { show, handleClose } = props;
  const [tableData, setTableData] = useState(INITIAL_DATA);
  const [error, setError] = useState(INITIAL_ERROR);
  const dispatch = useDispatch();
  const { app } = useSelector((state: RootState) => state.selectedApp);
  const [creationError, setCreationError] = useState(false);

  const handleSave = async () => {
    try {
      setCreationError(false);
      dispatch(setLoading());
      if (!app) {
        throw new Error("App not found");
      }
      const request: TCreateTableRequest = {
        name: tableData.name,
        tableName: tableData.tableName,
        isRootTable: tableData.isRootTable,
        csvInputString: tableData.csvInputString,
        appId: app.id as number,
        description: "",
      };
      const response = await createTable(request);
      dispatch(addTable(response.data))
      handleClose();
      setTableData(INITIAL_DATA); // reset value
    } catch (e) {
      console.log(e);
      setCreationError(true);
    } finally {
      dispatch(resetLoading());
    }
  };

  const isFormValid = () => {
    const errorObject = { ...INITIAL_ERROR };
    let isValid = true;
    console.log(tableData);
    if (tableData.name === "") {
      errorObject.name = true;
      isValid = false;
    }
    if (tableData.tableName === "") {
      errorObject.tableName = true;
      isValid = false;
    }
    if (tableData.csvInputString === "") {
      errorObject.csvInputString = true;
      isValid = false;
    }
    setError(errorObject);
    return isValid;
  };
  const onSubmit = async () => {
    if (!isFormValid()) {
      return;
    }
    await handleSave();
  };

  const setTableDataValue = (key: string, value: string | boolean) => {
    setTableData({
      ...tableData,
      [key]: value,
    });
  };
  const setCsvInputString = (csvString: string, tableName: string) => {
    setTableData({
      ...tableData,
      csvInputString: csvString,
      tableName: tableName,
    });
  };

  const onFileSelect = async (file: File | null) => {
    if (file) {
      const csvInputString = await extractCsvString(file);
      const tableName = file?.name.split(".")[0];
      setCsvInputString(csvInputString, tableName);
    } else {
      setCsvInputString("", "");
    }
  };

  return (
    <BasicModal show={show} handleClose={handleClose} title="Create New Table">
      <div className="create-table-container">
        <div className="error-text">
          {creationError ? "Error while creating table" : ""}
        </div>
        <div className="create-table-form">
          <TextField
            id="table-name"
            label="Table Name"
            variant="outlined"
            fullWidth
            required
            value={tableData.name}
            onChange={(e) => setTableDataValue("name", e.target.value)}
            error={error.name}
          />
          <div className="spacer"></div>
          <FileUpload
            onFileSelect={onFileSelect}
            error={error.csvInputString}
          />
          <div className="spacer"></div>
          <FormControlLabel
            control={
              <Checkbox
                checked={tableData.isRootTable}
                onChange={(e) =>
                  setTableDataValue("isRootTable", e.target.checked)
                }
              />
            }
            label="Show in home screen"
          />
        </div>
        <div className="create-table-footer">
          <Button onClick={onSubmit} variant="contained">
            Save
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

export default CreateTable;
