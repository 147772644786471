import React from 'react';
import './verticalLoader.css';

type TProps = {
    isLoading: boolean;
}
const VerticalLoader: React.FC<TProps> = (props) => {
  return (
    <div className="vertical-loader-container">
      <div className="vertical-loader">
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
        <div className="bar bar3"></div>
        <div className="bar bar4"></div>
        <div className="bar bar5"></div>
        <div className="bar bar6"></div>
        <div className="bar bar7"></div>
      </div>
    </div>
  );
};

export default VerticalLoader;