import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "./GridComponent.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

interface GridComponentProps {
  rowData: { [key: string]: any }[];
  actionButton?: any;
}

const GridComponent: React.FC<GridComponentProps> = ({
  rowData,
  actionButton,
}) => {
  const [columnDefs, setColumnDefs] = React.useState<any>([]); // [{ headerName: string, field: string }
  // Define column definitions based on the keys of the first object in rowData

  const updateColumnDefs = () => {
    if (rowData.length === 0) return;
    const columns = Object.keys(rowData[0]);
    const defs: any[] = columns.map((key) => ({
      headerName: key,
      field: key,
      flex: 1,
      cellStyle: { textAlign: "left" },
      headerClass: "custom-header",
      filter: true
    }));
    if (actionButton) {
      defs.push({
        headerName: "Linked to",
        field: "id",
        cellRenderer: actionButton,
        pinned: "right",
        headerClass: "custom-header custom-header-action",
        
      });
    }
    setColumnDefs(defs);
  };

  useEffect(() => {
    updateColumnDefs();
  }, [rowData]);

  //method to calculate required height of the grid
  const getGridHeight = () => {
    const rowHeight = 48;
    const headerHeight = 40;
    const foooterHeight = 40;
    return rowData.length * rowHeight + headerHeight + foooterHeight + 10;
  };

  return (
    <div className="ag-theme-alpine" style={{ height: `${getGridHeight()}px` }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
      />
    </div>
  );
};

export default GridComponent;
