import React from 'react';
import './Loader.css'; // Import the CSS file for styling

type TLoaderProps = {
    loading: boolean;
};

const LoaderOverlay: React.FC<TLoaderProps> = (props) => {
    return (
        props.loading ? (
            <div className="loader-overlay">
                <div className="loader"></div>
            </div>) : null

    );
};

export default LoaderOverlay;
