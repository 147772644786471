import exp from "constants";

export enum APP_TYPES {
  OWN = "own",
  SHARED = "shared",
}
export enum APP_ROLES {
  EDITOR = "editor",
  VIEWER = "viewer",
}

export interface TApp {
  id?: number;
  name: string;
  ownerId: number;
  createdAt?: number;
  updatedAt?: number;
}

export interface TCreateAppRequest {
  name: string;
}

export interface TTableMetaData{
  id?: number;
  appId: number;
  name: string;
  description: string;
  tableName: string;
  isRootTable: boolean;
  columns: string;
  createdAt?: number;
  updatedAt?: number;
  relations?: TRelations[];
}

export interface TCreateTableRequest{
  appId: number;
  name: string;
  description: string;
  tableName: string;
  isRootTable: boolean;
  csvInputString: string;
}

export interface TUpdateTableRequest{
  tableId: number;
  name?: string;
  description?: string;
  isRootTable?: boolean;
  csvInputString?: string;
}

export interface TShareAppRequest{
  email: string;
  role: APP_ROLES;
  appId: number;
}

export interface TRelations{
  id?: number;
  parentTableId: number;
  childTableId: number;
  parentColumnName: string;
  childColumnName: string;
  createdAt?: number;
  updatedAt?: number;
}

export interface TUser{
  appId: number;
  username: string;
  email: string;
  role: APP_ROLES;
  userId: number;
}