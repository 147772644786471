import React from "react";
import TablesContainer from "./TablesContainer";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { TTableMetaData } from "../../models";
import EmptyContainer from "../../components/EmptyContainer";

const RootTableContainer: React.FC = () => {
  const tables = useSelector((state: RootState) => state.selectedApp.tables);
  const [rootTables, setRootTables] = React.useState<TTableMetaData[]>([]);

  React.useEffect(() => {
    const rTables = tables.filter((table) => table.isRootTable);
    setRootTables(rTables);
  }, [tables]);

  return rootTables.length ? (
    <TablesContainer tables={rootTables} />
  ) : (
    <EmptyContainer text="Create new table..." />
  );
};

export default RootTableContainer;
