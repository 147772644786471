import React from "react";
import BasicModal from "../../components/BasicModal";
import "./shareApp.css";
import AddEmail from "./AddEmail";
import SharedUsers from "./SharedUsers";

type TProps = {
  show: boolean;
  handleClose: () => void;
};

const ShareApp: React.FC<TProps> = (props) => {
  const { show, handleClose } = props;

  return (
    <BasicModal show={show} handleClose={handleClose} title="Share app">
      <div className="share-app-container">
        <AddEmail handleClose={handleClose} />
        <SharedUsers />
        <div className="share-footer"></div>
      </div>
    </BasicModal>
  );
};

export default ShareApp;
