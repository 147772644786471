import React, { useEffect } from "react";
import { TTableMetaData } from "../../models";
import { fetchChildTableData, fetchTableData } from "../../apis/services";
import GridComponent from "../../components/GridComponent/GridComponent";
import { BsFillGridFill } from "react-icons/bs";
import { Button } from "@mui/material";
import { TbLinkPlus } from "react-icons/tb";
import ManageRelations from "../ManageRelations";
import LinkButton from "./LinkButton";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import VerticalLoader from "../../components/VerticalLoader";

interface GridContainerProps {
  table: TTableMetaData;
}
// todo setup local loader
// show error if failed to load data

const GridContainer: React.FC<GridContainerProps> = ({ table }) => {
  const [rowData, setRowData] = React.useState([]);
  const [showRelationModal, setShowRelationModal] = React.useState(false);
  const { parentTableId } = useParams();
  const { userRole } = useSelector((state: RootState) => state.selectedApp);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const loadTableData = async () => {
    try {
      if (table.id) {
        setLoading(true);
        let responseData = [];
        if (parentTableId) {
          responseData = await fetchChildTableData(
            parseInt(parentTableId as string),
            table.id,
            location.state.data
          );
        } else {
          responseData = await fetchTableData(table.id);
        }
        setRowData(responseData);
      }
    } catch (e) {
      setRowData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (table) {
      loadTableData();
    }
  }, [table]);
  return (
    <div className="table-grid-container">
      <div className="table-grid-header">
        <div className="table-grid-title">
          <BsFillGridFill /> {table.name}
        </div>

        <Button
          onClick={() => setShowRelationModal(true)}
          variant="contained"
          color="info"
          startIcon={<TbLinkPlus />}
        >
          {userRole === "viewer" ? "view" : "Add"} Relation
        </Button>
        <ManageRelations
          show={showRelationModal}
          handleClose={() => setShowRelationModal(false)}
          table={table}
        />
      </div>
      <div className="table-grid-content">
        {loading ? (
          <div>
            <VerticalLoader isLoading={loading} />
          </div>
        ) :
        <GridComponent
          rowData={rowData}
          actionButton={
            table.relations && table.relations?.length
              ? (params: any) => (
                  <LinkButton tableId={table.id as number} params={params} />
                )
              : undefined
          }
        />}
      </div>
    </div>
  );
};

export default GridContainer;
