import { configureStore, createAction } from '@reduxjs/toolkit'
import authReducer from './authReducer'
import loaderReducer from './loaderReducer'
import appReducer from './appReducer'
import selectedAppReducer from './selectedAppReducer'

export const revertAll = createAction('REVERT_ALL')
export const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    apps: appReducer,
    selectedApp: selectedAppReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

