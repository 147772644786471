import React, { useEffect } from "react";
import TableContainer from "./TablesContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { TRelations, TTableMetaData } from "../../models";

const ChildTableContainer: React.FC = () => {
  const { parentTableId } = useParams();
  const { tables } = useSelector((state: RootState) => state.selectedApp);
  const navigate = useNavigate();
  const [childTables, setChildTables] = React.useState<TTableMetaData[]>([]);
  const location = useLocation();

  useEffect(() => {
    updateTables();
  }, [parentTableId, tables]);

  const goToDRootTables = () => {
    navigate("/dashboard");
  };

  const updateTables = () => {
    if (!parentTableId) {
      goToDRootTables();
    }
    const parentTableIdInt = parseInt(parentTableId as string);
    const parentTable = tables.find((table) => table.id === parentTableIdInt);
    if (!parentTable) {
      goToDRootTables();
    }
    const parentRelations = parentTable?.relations || [];
    if (!parentRelations) {
      goToDRootTables();
    }
    if (!location.state.data) {
      goToDRootTables();
    }
    const childTableIds = parentRelations.map(
      (relation: TRelations) => relation.childTableId
    );
    const cTables = tables.filter((table) =>
      childTableIds.includes(table.id as number)
    );
    setChildTables(cTables);
  };

  return <TableContainer tables={childTables} />;
};

export default ChildTableContainer;
