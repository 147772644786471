import React from "react";
import BasicModal from "../../components/BasicModal";
import { TTableMetaData } from "../../models";
import "./ManageRelations.css";
import RelationsList from "./RelationsList";
import AddRelation from "./AddRelation";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type TProps = {
  show: boolean;
  handleClose: () => void;
  table: TTableMetaData;
};

const ManageRelations: React.FC<TProps> = (props) => {
  const { show, handleClose, table } = props;
  const { userRole } = useSelector((state: RootState) => state.selectedApp);

  return (
    <BasicModal
      show={show}
      handleClose={handleClose}
      title={`Manage Relations for ${table.name}`}
    >
      <div className="manage-relations">
        {userRole === "editor" || userRole === "owner" ? (
          <AddRelation table={table} />
        ) : null}
        <RelationsList table={table} />
      </div>
    </BasicModal>
  );
};

export default ManageRelations;
