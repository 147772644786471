import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { revertAll } from ".";

interface AuthState {
  isUserLoggedIn: boolean;
  user: User | null;
  loginError: string | null;
}

const initialState: AuthState = {
  isUserLoggedIn: false,
  user: null,
  loginError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(revertAll, () => initialState);
  },
  reducers: {
    setAuthState: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
      if (action.payload === null) {
        state.isUserLoggedIn = false;
      }
    },
    setUserLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isUserLoggedIn = action.payload;
    },
    setLoginError: (state, action: PayloadAction<string>) => {
      state.loginError = action.payload;
    },
    resetLoginError: (state) => {
      state.loginError = null;
    },
  },
});

export const {
  setAuthState,
  setUserLoggedIn,
  setLoginError,
  resetLoginError,
} = authSlice.actions;

export default authSlice.reducer;
