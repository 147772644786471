import { Button } from "@mui/material";
import BasicModal from "../../components/BasicModal";
import { deleteApp } from "../../apis/services";
import { APP_TYPES } from "../../models";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { setCurrentApp } from "../../store/selectedAppReducer";
import { RootState } from "../../store";
import { removeAppWithAppType } from "../../store/appReducer";

type TProps = {
  show: boolean;
  handleClose: () => void;
};
const RemoveApp: React.FC<TProps> = (props) => {
  const { show, handleClose } = props;
  const { app, appType } = useSelector((state: RootState) => state.selectedApp);
  const apps = useSelector((state: RootState) => state.apps);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const updateStore = (appId: number) => {
    dispatch(removeAppWithAppType({ appType: appType, appId: appId }));
    const currentOwnApps = apps[APP_TYPES.OWN].apps.filter(
      (item) => item.id !== appId
    );
    const currentSharedApps = apps[APP_TYPES.SHARED].apps;
    if (currentOwnApps.length > 0) {
      dispatch(setCurrentApp({ app: currentOwnApps[0], type: APP_TYPES.OWN }));
    } else if (currentSharedApps.length > 0) {
      dispatch(
        setCurrentApp({ app: currentSharedApps[0], type: APP_TYPES.SHARED })
      );
    } else {
      dispatch(setCurrentApp({ app: null, type: APP_TYPES.OWN }));
    }
  };
  const handleDeleteModal = async () => {
    if (!app?.id) return;
    try {
      setError(false);
      dispatch(setLoading());
      await deleteApp(app.id as number);
      updateStore(app.id as number);
      handleClose();
    } catch (e) {
      setError(true);
    } finally {
      dispatch(resetLoading());
    }
  };

  return (
    <BasicModal title="Delete Table" show={show} handleClose={handleClose}>
      <div className="delete-table">
        {error ? (
          <div className="error-text">
            Failed to delete app. Please try again
          </div>
        ) : null}
        <div className="delete-table-title">
          Are you sure you want to delete this table?
        </div>
        <div className="delete-table-actions">
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDeleteModal}>
            Delete
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

export default RemoveApp;
