import React from "react";
import "./EmptyContainer.css";
type TProps = {
  text: string;
};
const EmptyContainer: React.FC<TProps> = ({ text }) => {
  return (
    <div className="ec-main">
      <div className="ec-inner-text">{text}</div>
    </div>
  );
};

export default EmptyContainer;
