export const extractCsvString = (file: File): Promise<string> =>{
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
  
      fileReader.onload = () => {
        const csvString = fileReader.result as string;
        resolve(csvString);
      };
  
      fileReader.onerror = (err) => {
        reject(err);
      };
  
      fileReader.readAsText(file);
    });
  }

  //method to verify email format
export const verifyEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };