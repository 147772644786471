import { Button, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { TRelations, TTableMetaData } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { MdAdd } from "react-icons/md";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { createRelationService } from "../../apis/services";
import { addRelation } from "../../store/selectedAppReducer";

type TProps = {
  table: TTableMetaData;
};
const INITIAL_STATE: {
  parentColumnName: string;
  childColumnName: string;
  parentTableId: number;
  childTable: TTableMetaData | null;
} = {
  parentColumnName: "",
  childColumnName: "",
  parentTableId: 0,
  childTable: null,
};

const ERROR_STATE = {
  parentColumnName: false,
  childColumnName: false,
  childTable: false,
};

const AddRelation: React.FC<TProps> = ({ table }) => {
  const [serviceError, setServiceError] = useState<boolean>(false);
  const [currentState, setCurrentState] = useState(INITIAL_STATE);
  const { tables } = useSelector((state: RootState) => state.selectedApp);
  const [error, setError] = useState(ERROR_STATE);
  const dispatch = useDispatch();

  const verifyState = () => {
    let isValid = true;
    const errorState = { ...ERROR_STATE };
    if (currentState.parentColumnName === "") {
      errorState.parentColumnName = true;
      isValid = false;
    }
    if (currentState.childColumnName === "") {
      errorState.childColumnName = true;
      isValid = false;
    }
    if (currentState.childTable === null) {
      errorState.childTable = true;
      isValid = false;
    }
    setError(errorState);
    return isValid;
  };

  const getRelationRequest = (): TRelations => {
    return {
      parentTableId: table.id as number,
      childTableId: (currentState.childTable as any).id,
      parentColumnName: currentState.parentColumnName,
      childColumnName: currentState.childColumnName,
    };
  };

  const handleSave = async () => {
    if (!verifyState()) {
      return;
    }
    try {
      setServiceError(false);
      dispatch(setLoading());
      const request = getRelationRequest();
      const response = await createRelationService(request);
      setCurrentState(INITIAL_STATE);
      dispatch(
        addRelation({
          tableId: table.id as number,
          relation: response.data as TRelations,
        })
      );
    } catch (e) {
      console.log(e);
      setServiceError(true);
    } finally {
      dispatch(resetLoading());
    }
  };
  const getColumns = () => {
    return table.columns.split(",");
  };

  const getChildTabels = () => {
    return tables.filter((t) => t.id !== table.id);
  };

  const getChildColumns = () => {
    if (currentState.childTable == null) {
      return [];
    }
    return (currentState.childTable as any).columns.split(",");
  };

  const setParentColumn = (column: string) => {
    setCurrentState({ ...currentState, parentColumnName: column });
  };

  const setChildTable = (table: TTableMetaData) => {
    setCurrentState({
      ...currentState,
      childTable: table,
      childColumnName: "",
    });
  };

  const setChildColumn = (column: string) => {
    setCurrentState({ ...currentState, childColumnName: column });
  };

  return (
    <div className="manage-relation-card">
      {serviceError && <div className="error-text">Something went wrong</div>}
      <div className="create-relation-fields">
        <TextField
          id="outlined-select-parent_col"
          select
          label="Select Parent Column"
          variant="standard"
          value={currentState.parentColumnName}
          fullWidth
          error={error.parentColumnName}
        >
          {getColumns().map((column) => (
            <MenuItem
              key={column}
              value={column}
              onClick={() => setParentColumn(column)}
            >
              {column}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-child_table"
          select
          label="Select Child table"
          variant="standard"
          value={
            currentState.childTable ? (currentState.childTable as any).id : ""
          }
          fullWidth
          error={error.childTable}
        >
          {getChildTabels().map((tab: TTableMetaData) => (
            <MenuItem
              key={tab.id}
              value={tab.id}
              onClick={() => setChildTable(tab)}
            >
              {tab.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-cchild_col"
          select
          label="Select Child column"
          variant="standard"
          value={currentState.childColumnName}
          fullWidth
          error={error.childColumnName}
        >
          {getChildColumns().map((column: string, index: number) => (
            <MenuItem
              key={index}
              value={column}
              onClick={() => setChildColumn(column)}
            >
              {column}
            </MenuItem>
          ))}
        </TextField>
        <Button
          onClick={handleSave}
          variant="contained"
          style={{ height: "40px", width: "300px" }}
          startIcon={<MdAdd />}
        >
          Add
        </Button>
      </div>
    </div>
  );
};


export default AddRelation;