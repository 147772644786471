import { Button } from "@mui/material";
import BasicModal from "../../components/BasicModal";
import { deleteTableService } from "../../apis/services";
import { TTableMetaData } from "../../models";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { removeTable } from "../../store/selectedAppReducer";

type TProps = {
  show: boolean;
  handleClose: () => void;
  table: TTableMetaData;
};
const Deletetable: React.FC<TProps> = (props) => {
  const { show, handleClose, table } = props;
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteModal = async () => {
    if (!table.id) return;
    try {
      setError(false);
      dispatch(setLoading());
      await deleteTableService(table.id);
      dispatch(removeTable(table.id));
      handleClose();
    } catch (e) {
      setError(true);
    } finally {
      dispatch(resetLoading());
    }
  };

  return (
    <BasicModal title="Delete Table" show={show} handleClose={handleClose}>
      <div className="delete-table">
        {error ? <div className="error-text">
          Failed to delete table. Please try again
        </div>: null}
        <div className="delete-table-title">
          Are you sure you want to delete this table?
        </div>
        <div className="delete-table-actions">
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDeleteModal}>
            Delete
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

export default Deletetable;
