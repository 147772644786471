import { Button, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { APP_ROLES, TShareAppRequest } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { shareAppService } from "../../apis/services";
import { verifyEmail } from "../../common/utils";
import { addUser } from "../../store/selectedAppReducer";

interface AddEmailProps {
  handleClose: () => void;
}

const AddEmail: React.FC<AddEmailProps> = (props) => {
  const { handleClose } = props;
  const [emailId, setEmailId] = useState("");
  const [role, setRole] = useState<APP_ROLES>(APP_ROLES.VIEWER);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const { app, userRole} = useSelector((state: RootState) => state.selectedApp);
  const [serviceError, setServiceError] = useState("");

  const getRequest = (): TShareAppRequest => {
    return {
      email: emailId,
      role,
      appId: app?.id as number,
    };
  };

  const addEmailService = async () => {
    try {
      dispatch(setLoading());
      const responseData = await shareAppService(getRequest());
      dispatch(addUser(responseData))
      handleClose();
      setEmailId("");
      setError(false);
    } catch (e: any) {
      setServiceError(e.response.data);
    } finally {
      dispatch(resetLoading());
    }
  };

  const isFormValid = () => {
    if (!verifyEmail(emailId)) {
      return false;
    }
    return true;
  };

  const onShare = async () => {
    if (!isFormValid()) {
      setError(true);
      return;
    }
    await addEmailService();
  };

  return (
    <div className="share-app-form">
      <div className="error-text">{serviceError}</div>
      <div className="add-email-container">
        <TextField
          label="Enter email id"
          variant="outlined"
          value={emailId}
          onChange={(e) => setEmailId(e.target.value)}
          error={error}
          style={{ width: "70%"}}
        />
        <TextField
          id="outlined-select-currency"
          select
          label="Select role"
          defaultValue={APP_ROLES.VIEWER}
          variant="outlined"
          value={role}
          style={{ width: "20%"}}
        >
          {Object.values(APP_ROLES).map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => setRole(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" onClick={onShare} disabled={userRole === "viewer"}>
            Share
        </Button>
      </div>
    </div>
  );
};

export default AddEmail;
