import React, { useEffect, useState } from "react";
import BasicModal from "../../components/BasicModal";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { TTableMetaData, TUpdateTableRequest } from "../../models";
import FileUpload from "../../components/FileUpload/FileUpload";
import { extractCsvString } from "../../common/utils";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { updateTableService } from "../../apis/services";
import { updateTable } from "../../store/selectedAppReducer";
import './EditTable.css';

interface EditTableProps {
  show: boolean;
  handleClose: () => void;
  table: TTableMetaData;
}

const INITIAL_ERROR = {
  name: false,
  isRootTable: false,
  csvInputString: false,
};
const INITIAL_DATA = {
  name: "",
  isRootTable: false,
  csvInputString: "",
};

const EditTable: React.FC<EditTableProps> = ({ show, handleClose, table }) => {
  const [updationError, setUpdationError] = useState(false);
  const [tableData, setTableData] = useState(INITIAL_DATA);
  const [error, setError] = useState(INITIAL_ERROR);
  const dispatch = useDispatch();
  const { app } = useSelector((state: RootState) => state.selectedApp);

  const updateTableData = () => {
    const updatedTableData = {
      ...tableData,
      name: table.name,
      isRootTable: table.isRootTable,
    };
    setTableData(updatedTableData);
  };

  useEffect(() => {
    updateTableData();
  }, [table]);

  const isValid = () => {
    let isValid = true;
    const errorObj = { ...INITIAL_ERROR };

    if (!tableData.name) {
      isValid = false;
      errorObj.name = true;
    }
    setError(errorObj);
    return isValid;
  };

  const handleSave = async () => {
    try {
      setUpdationError(false);
      dispatch(setLoading());
      if (!app) {
        throw new Error("App not found");
      }
      const request: TUpdateTableRequest = {
        tableId: table.id as number,
        name: tableData.name !== table.name ? tableData.name : undefined,
        isRootTable:
          tableData.isRootTable !== table.isRootTable
            ? tableData.isRootTable
            : undefined,
        csvInputString:
          tableData.csvInputString !== ""
            ? tableData.csvInputString
            : undefined,
      };
      const response = await updateTableService(request);
      dispatch(updateTable(response.data));
      handleClose();
      setTableData(INITIAL_DATA); // reset value
    } catch (e) {
      console.log(e);
      setUpdationError(true);
    } finally {
      dispatch(resetLoading());
    }
  };

  const onSubmit = () => {
    if (!isValid()) {
      return;
    }
    handleSave();
  };

  const setTableDataValue = (key: string, value: string | boolean) => {
    setTableData({
      ...tableData,
      [key]: value,
    });
  };
  const setCsvInputString = (csvString: string) => {
    setTableData({
      ...tableData,
      csvInputString: csvString,
    });
  };

  const onFileSelect = async (file: File | null) => {
    if (file) {
      const csvInputString = await extractCsvString(file);
      setCsvInputString(csvInputString);
    } else {
      setCsvInputString("");
    }
  };

  return (
    <BasicModal show={show} handleClose={handleClose} title="Create New Table">
      <div className="update-table-container">
        <div className="error-text">
          {updationError ? "Error while updating table" : ""}
        </div>
        <div className="update-table-form">
          <TextField
            id="table-name"
            label="Table Name"
            variant="outlined"
            fullWidth
            required
            value={tableData.name}
            onChange={(e) => setTableDataValue("name", e.target.value)}
            error={error.name}
          />
          <div className="spacer"></div>
          <FileUpload
            onFileSelect={onFileSelect}
            error={error.csvInputString}
          />
          <div className="spacer"></div>
          <FormControlLabel
            control={
              <Checkbox
                checked={tableData.isRootTable}
                onChange={(e) =>
                  setTableDataValue("isRootTable", e.target.checked)
                }
              />
            }
            label="Show in home screen"
          />
        </div>
        <div className="update-table-footer">
          <Button onClick={onSubmit} variant="contained">
            Save Changes
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

export default EditTable;
