import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
    isLoading: boolean;
}

const initialState: LoaderState = {
    isLoading: false,
};

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.isLoading =true;
        },
        resetLoading: (state) => {
            state.isLoading = false;
        }
    },
});

export const { setLoading, resetLoading } = loaderSlice.actions;

export default loaderSlice.reducer;