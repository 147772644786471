import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { TTableMetaData } from "../../models";
import "./ManageTables.css";
import { MdPushPin } from "react-icons/md";
import Deletetable from "./DeleteTable";
import EditTable from "../EditTable/EditTable";
import EmptyContainer from "../../components/EmptyContainer";

const ManageTables: React.FC = () => {
  const { tables, userRole } = useSelector(
    (state: RootState) => state.selectedApp
  );
  const [showDeleteTable, setShowDeleteTable] = React.useState(false);
  const [showEditTable, setShowEditTable] = React.useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [height, setheight] = React.useState(400);
  const [selectedTable, setSelectedTable] = React.useState<TTableMetaData>(
    tables[0]
  );

  const isEditorOrOwner = () => {
    return userRole === "editor" || userRole === "owner";
  };
  const calculateTableHeight = () => {
    if (ref.current) {
      const height = ref.current.clientHeight;
      setheight(height - 80);
    }
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);
    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const handleDeleteTable = (table: TTableMetaData) => {
    setShowDeleteTable(true);
    setSelectedTable(table);
  };

  const handleEditTable = (table: TTableMetaData) => {
    setSelectedTable(table);
    setShowEditTable(true);
  };

  if (tables.length === 0) {
    return <EmptyContainer text="Create new table..." />;
  }

  return (
    <div className="manage-tables" ref={ref}>
      <TableContainer component={Paper} style={{ maxHeight: height + "px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Table name</TableCell>
              <TableCell align="center">Pinned to home</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tables.map((table: TTableMetaData) => (
              <TableRow
                className="manage-table-row"
                key={table.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {table.name}
                </TableCell>
                <TableCell align="center">
                  {table.isRootTable ? (
                    <MdPushPin size={20} color="grey" />
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    className="action-icon"
                    onClick={() => handleEditTable(table)}
                    disabled={!isEditorOrOwner()}
                  >
                    <MdEdit size={20} />
                  </IconButton>
                  <IconButton
                    className="action-icon"
                    onClick={() => handleDeleteTable(table)}
                    disabled={!isEditorOrOwner()}
                  >
                    <MdDelete size={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditTable
        show={showEditTable}
        handleClose={() => setShowEditTable(false)}
        table={selectedTable}
      />
      <Deletetable
        show={showDeleteTable}
        handleClose={() => setShowDeleteTable(false)}
        table={selectedTable}
      />
    </div>
  );
};

export default ManageTables;
