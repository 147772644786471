import { IconButton } from "@mui/material";
import { MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const LinkButton = ({ tableId, params }: { tableId: number; params: any }) => {
  const navigate = useNavigate();

  const navigateToChildTable = () => {
    navigate(`/dashboard/child/${tableId}`, { state: { data: params.data } });
  };
  return (
    <IconButton onClick={navigateToChildTable}>
      <MdArrowForward />
    </IconButton>
  );
};

export default LinkButton;
