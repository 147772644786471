import styled from "@emotion/styled";
import { Button, IconButton } from "@mui/material";

export const ActionButton = styled(Button)({
  backgroundColor: "white",
  color: "black",
  textTransform: "capitalize",
  width: "100%",
  "&:hover": {
    backgroundColor: "lightblue",
  },
});

export const MenuButton = styled(Button)({
  color: "#6c6a6a",
  textTransform: "capitalize",
  padding: "5px 25px",
  justifyContent: "flex-start",
  borderRadius: "20px",
  width: "100%",
  "&:hover": {
    backgroundColor: "lightgrey",
  },
  "&.selected-menu-btn": {
    backgroundColor: "#d0d8e1",
  },
});

export const IconRoundedButton = styled(IconButton)({
  fontSize: "20px",
});
