import React, { useState } from 'react';
import './FileUpload.css';
import './FileUpload.css';
import { MdCloudUpload } from 'react-icons/md';

type TProps = {
    onFileSelect: (file: File|null) => void;
    error?: boolean;
    };
const FileUpload: React.FC<TProps> = (props) => {
    const { onFileSelect, error } = props;
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const updateFile = async (file: File) => {
    onFileSelect(file);
    setFile(file);
  }
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'text/csv') {
      updateFile(droppedFile);
    } else {
      // Handle invalid file type
      console.log('Please upload a CSV file.');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    if (selectedFile && selectedFile.type === 'text/csv') {
      updateFile(selectedFile);
    } else {
      // Handle invalid file type
      console.log('Please upload a CSV file.');
    }
  };

  return (
    <div
      className={`file-upload ${dragging ? 'drag-active' : ''} ${error === true? 'error' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="file-upload-content">
        <div className="file-upload-icon">
         <MdCloudUpload size={40}/>
        </div>
        <div className="file-upload-text">
          <p>Drag and drop</p>
          <p>or</p>
          <label htmlFor="file-input" className="file-upload-button">
            Browse File
          </label>
          <input
            id="file-input"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      {file && (
        <div className="file-item">
          {file.name}
        </div>
      )}
    </div>
  );
};

export default FileUpload;