import { api } from ".";
import { TApp, TCreateAppRequest, TCreateTableRequest, TRelations, TShareAppRequest, TTableMetaData, TUpdateTableRequest } from "../models";
import URLS from "./urlConstants";

export const userLogin = async () => {
    await api.post(URLS.LOGIN);
}
export const fetchOwnApps = async () => {
    const response = await api.get(URLS.GET_MY_APPS);
    return response;
}

export const fetchSharedApps = async () => {
    const response = await api.get(URLS.GET_SHARED_APPS);
    return response;
}

export const createApp = async (data: TCreateAppRequest): Promise<TApp> => {
    const response:any = await api.post(URLS.CREATE_APP, data);
    return response.data;
}
export const deleteApp = async (appId: number) => {
    const response = await api.delete(URLS.DELETE_APP + appId);
    return response;
}

export const fetchUsersByApp = async (appId: number) => {
    const response = await api.get(URLS.GET_APP_USERS + appId);
    return response.data;
}

export const createTable = async (data: TCreateTableRequest) => {  
    const response = await api.post(URLS.CREATE_TABLE, data);
    return response;
}

export const fetchTables = async (appId: number): Promise<TTableMetaData[]> => {
    const response = await api.get(URLS.GET_TABLES + appId);
    return response.data;
}

export const fetchTableData = async (tableId: number) => {
    const response = await api.get(URLS.GET_TABLE_DATA + tableId);
    return response.data;
}

export const shareAppService = async (data: TShareAppRequest) => {
    const response = await api.post(URLS.SHARE_APP, data);
    return response.data;
}

export const unshareAppService = async (data: TShareAppRequest) => {
    const response = await api.post(URLS.UNSHARE_APP, data);
    return response.data;
}

export const updateAppRoleService = async (data: TShareAppRequest) => {
    const response = await api.put(URLS.UPDATE_APP_ROLE, data);
    return response.data;
}

export const deleteTableService = async (tableId: number) => {
    const response = await api.delete(URLS.DELETE_TABLE + tableId);
    return response;
}

export const createRelationService = async (data: TRelations) => {
    const response = await api.post(URLS.CREATE_RELATION, data);
    return response;
}

export const deleteRelationService = async (relationId: number) => {
    const response = await api.delete(URLS.DELETE_RELATION + relationId);
    return response;
}

export const fetchChildTableData = async (parentTableId: number, childTableId: number, rowData: Object) => {
    const url = URLS.GET_CHILD_TABLE_DATA.replace("{parentTableId}", parentTableId.toString()).replace("{childTableId}", childTableId.toString());
    const response = await api.post(url, rowData);
    return response.data;
}

export const updateTableService = async (data: TUpdateTableRequest) => {
    const response = await api.put(URLS.UPDATE_TABLE, data);
    return response;
}