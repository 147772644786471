import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APP_TYPES, TApp, TRelations, TTableMetaData, TUser } from "../models";
import { revertAll } from ".";

interface SelectedAppState {
  app: TApp | null;
  tables: TTableMetaData[];
  users: TUser[];
  appType: APP_TYPES;
  userRole: string;
}

const initialState: SelectedAppState = {
  app: null,
  tables: [],
  users: [],
  appType: APP_TYPES.OWN,
  userRole: "",
};

const selectedAppSlice = createSlice({
  name: "selectedApp",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(revertAll, () => initialState);
  }, 
  reducers: {
    setCurrentApp: (state, action: PayloadAction<{app: TApp|null, type: APP_TYPES}>) => {
      state.app = action.payload.app;
      state.appType = action.payload.type;
      state.tables = [];
      state.users = [];
    },
    setTables: (state, action: PayloadAction<TTableMetaData[]>) => {
      state.tables = action.payload;
    },
    addTable: (state, action: PayloadAction<TTableMetaData>) => {
      state.tables.push(action.payload);
    },
    removeTable: (state, action: PayloadAction<number>) => {
      state.tables = state.tables.filter(
        (table) => table.id !== action.payload
      );
    },
    updateTable: (state, action: PayloadAction<TTableMetaData>) => {
      const index = state.tables.findIndex(
        (table) => table.id === action.payload.id
      );
      if (index !== -1) {
        state.tables[index] = action.payload;
      }
    },
    addRelation: (
      state,
      action: PayloadAction<{ tableId: number; relation: TRelations }>
    ) => {
      const table = state.tables.find(
        (table) => table.id === action.payload.tableId
      );
      if (table) {
        table.relations = table.relations || [];
        table.relations.push(action.payload.relation);
      }
    },
    deleteRelation: (state, action: PayloadAction<{relationId: number, tableId:number}>) => {
      const table = state.tables.find(
        (table) => table.id === action.payload.tableId
      );
      if (table) {
        table.relations = table.relations?.filter(
          (relation) => relation.id !== action.payload.relationId
        );
      }
    },
    setUsers: (state, action: PayloadAction<{users: TUser[], currentUserEmail: string}>) => {
      state.users = action.payload.users;
      state.userRole = state.users.find(
        (user) => user.email === action.payload.currentUserEmail
      )?.role || "";
    },
    addUser: (state, action: PayloadAction<TUser>) => {
      state.users.push(action.payload);
    },
    updateUser: (state, action: PayloadAction<TUser>) => {
      const index = state.users.findIndex(
        (user) => user.userId === action.payload.userId
      );
      if (index !== -1) {
        state.users[index] = action.payload;
      }
    },
    removeUser: (state, action: PayloadAction<number>) => {
      state.users = state.users.filter(
        (user) => user.userId !== action.payload
      );
    },
  },
});

export const {
  setCurrentApp,
  setTables,
  addTable,
  removeTable,
  addRelation,
  updateTable,
  setUsers,
  updateUser,
  removeUser,
  addUser,
  deleteRelation
} = selectedAppSlice.actions;

export default selectedAppSlice.reducer;
