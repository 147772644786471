const URLS =  {
    LOGIN: "/user/login",
    GET_MY_APPS: "/app/list/own-apps",
    GET_SHARED_APPS: "/app/list/shared-apps",
    CREATE_APP: "/app/create",
    UPDATE_APP: "/app/update/",
    DELETE_APP: "/app/delete/",
    SHARE_APP: "/app-permission/share",
    UNSHARE_APP: "/app-permission/unshare",
    UPDATE_APP_ROLE: "/app-permission/update-permission",
    GET_APP_USERS: "/app-permission/users/",
    CREATE_TABLE: "/table/create",
    GET_TABLES: "/table/list/",
    GET_TABLE_DATA: "/table/data/",
    GET_CHILD_TABLE_DATA:"/table/data/parent-id/{parentTableId}/child-id/{childTableId}",
    DELETE_TABLE: "/table/delete/",
    UPDATE_TABLE: "/table/update",
    CREATE_RELATION: "/relations/create",
    DELETE_RELATION: "/relations/delete/",
}

export default URLS;