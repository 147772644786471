import React, { useState } from "react";
import BasicModal from "../../components/BasicModal";
import { Button, TextField } from "@mui/material";
import "./createApp.css";
import { APP_TYPES, TApp, TCreateAppRequest } from "../../models";
import { createApp } from "../../apis/services";
import { useDispatch, useSelector } from "react-redux";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { addApp } from "../../store/appReducer";
import { RootState } from "../../store";
import { setCurrentApp } from "../../store/selectedAppReducer";

type TProps = {
  show: boolean;
  handleClose: () => void;
};

const CreateApp: React.FC<TProps> = (props) => {
  const { show, handleClose } = props;
  const [appName, setAppName] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const {app} = useSelector((state:RootState) => state.selectedApp);

  const handleSave = async () => {
    const request: TCreateAppRequest = {
      name: appName,
    };
    try {
      dispatch(setLoading());
      const response: TApp = await createApp(request);
      dispatch(addApp({ appType: APP_TYPES.OWN, app: response }));
      if(!app) {
        dispatch(setCurrentApp({app: response, type: APP_TYPES.OWN}));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(resetLoading());
    }
  };
  const onSubmit = async () => {
    if (appName === "") {
      setError(true);
      return;
    }
    await handleSave();
    handleClose();
    setAppName(""); // reset value
  };

  return (
    <BasicModal show={show} handleClose={handleClose} title="Create New App">
      <div className="create-app-container">
        <div className="create-app-form">
          <TextField
            label="Enter app name"
            variant="standard"
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
            error={error}
            fullWidth
          />
        </div>
        <div className="create-footer">
          <Button onClick={onSubmit} variant="contained">
            Create
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

export default CreateApp;
