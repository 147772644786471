import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  Avatar,
  Divider,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { APP_ROLES, TShareAppRequest, TUser } from "../../models";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { unshareAppService, updateAppRoleService } from "../../apis/services";
import { removeUser, updateUser } from "../../store/selectedAppReducer";

interface SharedUsersProps {
  // define your props here
}

const SharedUsers: React.FC<SharedUsersProps> = () => {
  const { users, userRole } = useSelector(
    (state: RootState) => state.selectedApp
  );
  const dispatch = useDispatch();

  const isEditorOrOwner = () => {
    return userRole === "editor" || userRole === "owner";
  };
  const getUserRoles = () => {
    if (!isEditorOrOwner()) {
      return [APP_ROLES.VIEWER];
    }
    return Object.values(APP_ROLES);
  };
  const updateRole = async (user: TUser, newRole: APP_ROLES) => {
    const requestBody: TShareAppRequest = {
      email: user.email,
      role: newRole,
      appId: user.appId,
    };
    try {
      dispatch(setLoading());
      const response = await updateAppRoleService(requestBody);
      dispatch(updateUser(response));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(resetLoading());
    }
  };

  const removeAccess = async (user: TUser) => {
    const requestBody: TShareAppRequest = {
      email: user.email,
      role: user.role,
      appId: user.appId,
    };
    try {
      dispatch(setLoading());
      await unshareAppService(requestBody);
      dispatch(removeUser(user.userId));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(resetLoading());
    }
  };

  return (
    <div>
      <h5>People with access</h5>
      <div className="people-with-access">
        {users.map((user) => (
          <div key={user.userId} className="user-with-access">
            <IconButton
              aria-label="fingerprint"
              color="success"
              onClick={() => {}}
            >
              <Avatar alt={user.email}>
                {user.email.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
            <div className="user-with-access-email">{user.email}</div>
            {(user.role as any) === "owner" ? (
              <div className="user-with-access-role">Owner</div>
            ) : (
              <TextField
                id={`${user.userId}`}
                select
                label=""
                //   defaultValue={APP_ROLES.VIEWER}
                variant="standard"
                value={user.role}
                InputProps={{
                  disableUnderline: true,
                }}
              >
                {getUserRoles().map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    onClick={() => updateRole(user, option)}
                  >
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </MenuItem>
                ))}
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={() => removeAccess(user)}
                  disableRipple
                  className="error-text"
                  disabled={!isEditorOrOwner()}
                >
                  <div className="remove-access">Remove access</div>
                </MenuItem>
              </TextField>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedUsers;
