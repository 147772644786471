import { Button, IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "./appdetails.css";
import CreateTable from "../CreateTable/CreateTable";
import ShareApp from "../ShareApp";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MdAdd, MdArrowBack, MdDelete, MdShare } from "react-icons/md";
import RemoveApp from "../RemoveApp/RemoveApp";
import { APP_TYPES } from "../../models";
import EmptyContainer from "../../components/EmptyContainer";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const AppDetails: React.FC = () => {
  const { app, appType, userRole } = useSelector((state: any) => state.selectedApp);
  const [showCreateTable, setShowCreateTable] = React.useState(false);
  const [showdeleteApp, setShowDeleteApp] = React.useState(false);
  const [showShareApp, setShowShareApp] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToManageTables = () => {
    navigate("manage");
  };
  const isEditorOrOwner = ()=>{
    return userRole === "editor" || userRole === "owner";
  }
  return !app ? (
   <EmptyContainer text="Create new app"/>
  ) : (
    <div className="app-details">
      <div className="app-details-header">
        <div className="app-details-title">
          {location.pathname.includes("child")?<IconButton onClick={() => navigate(-1)}>
            <MdOutlineKeyboardBackspace />
          </IconButton>:null}
          {app.name}</div>
        {location.pathname.includes("manage") ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            startIcon={<MdArrowBack />}
          >
            Back to home
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowShareApp(true)}
          startIcon={<MdShare />}
        >
          Share
        </Button>
        <ShareApp
          show={showShareApp}
          handleClose={() => setShowShareApp(false)}
        />
       {isEditorOrOwner() && <Button
          variant="contained"
          color="primary"
          onClick={() => setShowCreateTable(true)}
          startIcon={<MdAdd />}
        >
          Create table
        </Button>}
        {location.pathname.includes("manage") ? null : (
          <Button
            variant="contained"
            color="primary"
            onClick={navigateToManageTables}
          >
            Manage tables
          </Button>
        )}
        {appType === APP_TYPES.OWN ? (<Button
          variant="contained"
          color="primary"
          onClick={() => setShowDeleteApp(true)}
          startIcon={<MdDelete />}
        >
          Delete App
        </Button>
        ) : null}
        <CreateTable
          show={showCreateTable}
          handleClose={() => setShowCreateTable(false)}
        />
        
          <RemoveApp
            show={showdeleteApp}
            handleClose={() => setShowDeleteApp(false)}
          />
      </div>
      <Outlet />
    </div>
  );
};

export default AppDetails;
