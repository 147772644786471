import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, redirect } from 'react-router-dom';
import { RootState } from '../store';

interface PrivateRouteProps {
    children: React.ReactNode;
    redirectPath: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    children,
    redirectPath,
}) => {
    const { user, isUserLoggedIn } = useSelector((state: RootState) => state.auth);
    console.log(user, isUserLoggedIn);
    if (!user || !isUserLoggedIn) {
        return <Navigate to="/" replace={true} />
    }
    return <React.Fragment>{children}</React.Fragment>;
};

export default PrivateRoute;