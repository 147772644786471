import React from "react";
import { FaFolder } from "react-icons/fa";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { APP_TYPES, TApp } from "../../models";
import VerticalLoader from "../../components/VerticalLoader";
import { FaCircleExclamation } from "react-icons/fa6";
import { MenuButton } from "../../components/CustomButtons";
import { setCurrentApp } from "../../store/selectedAppReducer";
import { useNavigate } from "react-router-dom";

type TProps = {
  isLoaded: boolean;
  apps: TApp[];
  appType: APP_TYPES;
  isErrored: boolean;
};

const MenuSection: React.FC<TProps> = (props) => {
  const { app } = useSelector((state: RootState) => state.selectedApp);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAppSelection = (selectedApp: TApp) => {
    if (app?.id !== selectedApp.id) {
      dispatch(setCurrentApp({ app: selectedApp, type: props.appType }));
    }
    navigate(`/dashboard`);
  };

  return (
    <div className="app-list-section">
      <div className="app-list-title">{props.appType} Apps</div>
      {!props.isLoaded ? (
        <VerticalLoader isLoading={!props.isLoaded} />
      ) : props.isErrored ? (
        <div className="error-text">
          <span>
            <FaCircleExclamation />
          </span>{" "}
          <span>Error loading apps</span>
        </div>
      ) : (
        <div className="app-list-items">
          {props.apps.map((item) => (
            <MenuButton
              key={item.id}
              variant="text"
              color="primary"
              startIcon={<FaFolder />}
              onClick={() => handleAppSelection(item)}
              className={app?.id === item.id ? "selected-menu-btn" : ""}
            >
              {item.name}
            </MenuButton>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuSection;
