import React from 'react';
import GridContainer from './GridContainer';
import { TTableMetaData } from '../../models';

type TProps = {
    tables: TTableMetaData[];
}

const TablesContainer: React.FC<TProps> = ({tables}) => {

    return (
        <div className='table-container'>
            <div>
            {
                tables.map((table: TTableMetaData) => {
                    return (
                       <GridContainer key={table.id} table={table} />
                    );
                })
            }
            </div>
        </div>
    );
}

export default TablesContainer;