import React, { useEffect } from "react";
import LeftMenu from "./LeftMenu";
import "./Dashboard.css";
import DashboardContent from "./DashboardContent";
import { useDispatch, useSelector } from "react-redux";
import { TTableMetaData } from "../../models";
import { fetchTables, fetchUsersByApp } from "../../apis/services";
import { setTables, setUsers } from "../../store/selectedAppReducer";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { RootState } from "../../store";

const Dashboard: React.FC = () => {
  const { app } = useSelector((state: RootState) => state.selectedApp);
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const loadTables = async () => {
    if (app) {
      const response: TTableMetaData[] = await fetchTables(app.id as number);
      dispatch(setTables(response));
    }
  };

  const loadUsers = async () => {
    if(app){
      const response = await fetchUsersByApp(app.id as number);
      dispatch(setUsers({users: response, currentUserEmail: user?.email || ""}));
    }
  };

  const loadData = async () => {
    try {
      dispatch(setLoading());
      await loadTables();
      await loadUsers();
    }catch(e){
      console.log(e);
    }finally{
      dispatch(resetLoading());
    }
  }
  useEffect(() => {
    if (app) {
      loadData();
    }
  }, [app]);

  return (
    <div className="dashboard-container">
      <LeftMenu />
      <DashboardContent />
    </div>
  );
};

export default Dashboard;
