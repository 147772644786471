import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "../App";
import LoginScreen from "./LoginScreen/LoginScreen";
import ErrorBoundary from "../components/ErrorBoundry";
import PrivateRoute from "../components/PrivateRoute";
import Dashboard from "./Dashboard/Dashboard";
import ManageTables from "./ManageTables";
import RootTableContainer from "./AppDetails/RootTableContainer";
import ChildTableContainer from "./AppDetails/ChildTableContainer";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "",
        element: <LoginScreen />,
      },
      {
        path: "dashboard",
        element: (
          <PrivateRoute redirectPath="/">
            <Dashboard />
          </PrivateRoute>
        ),
        children: [
          {
            path: "",
            element: <RootTableContainer />,
          },
          {
            path: "child/:parentTableId",
            element: <ChildTableContainer />,
          },
          {
            path: "manage",
            element: <ManageTables />,
          },
        ],
      },
    ],
  },
]);

const RouterProviderWrapper = () => <RouterProvider router={routes} />;

export default RouterProviderWrapper;
