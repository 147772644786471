import React from 'react';
import './Navigation.css';
import { RiFileExcel2Fill } from "react-icons/ri";
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { RootState, revertAll } from '../../store';


const Navigation: React.FC = () => {
    const user: User|null = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        auth.signOut().then(() => {
            console.log('User signed out');
            dispatch(revertAll());
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="navigation-container">
            <div className='logo'>
                <RiFileExcel2Fill />
                <div>XLSAHI</div>
            </div>
            {user && <div className='navigation-content'>
                <Tooltip title="settings">
                    <IconButton aria-label="fingerprint" color="success" onClick={handleClick}>
                        <Avatar alt="Remy Sharp" src={user.photoURL ?? ""} />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </div>}
        </div>
    );
};

export default Navigation;