import * as React from "react";
import Modal from "@mui/material/Modal";
import "./BasicModal.css";
import { Button, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";
import { IconRoundedButton } from "../CustomButtons";

type TProps = {
  show: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  title?: string;
};

export default function BasicModal(props: TProps) {
  return (
    <Modal
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-children">
        <div className="modal-header">
          <h3 id="modal-modal-title">{props.title}</h3>
          <IconRoundedButton onClick={props.handleClose}>
            <MdClose />
          </IconRoundedButton>
        </div>
        {props.children}
      </div>
    </Modal>
  );
}
