import React from "react";
import { TTableMetaData } from "../../models";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { MdDelete, MdTableView, MdViewColumn } from "react-icons/md";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { deleteRelationService } from "../../apis/services";
import { resetLoading, setLoading } from "../../store/loaderReducer";
import { deleteRelation } from "../../store/selectedAppReducer";

interface RelationsListProps {
  table: TTableMetaData;
}

const RelationsList: React.FC<RelationsListProps> = ({ table }) => {
  const { tables, userRole } = useSelector(
    (state: RootState) => state.selectedApp
  );
  const [serviceError, setServiceError] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const getChildTable = (childTableId: number) => {
    return tables.find((table) => table.id === childTableId);
  };

  const isEditorOrOwner = () => {
    return userRole === "editor" || userRole === "owner";
  };

  const handledelete = async (relationId: number) => {
    try {
      setServiceError(false);
      dispatch(setLoading());
      await deleteRelationService(relationId);
      dispatch(deleteRelation({ tableId: table.id as number, relationId }));
    } catch (error) {
      setServiceError(true);
    } finally {
      dispatch(resetLoading());
    }
  };

  return (
    <div className="relatons-list-container">
      <h5>Relations</h5>
      {serviceError ? (
        <div className="error-text">Something went wrong</div>
      ) : null}
      {table.relations?.length ? (
        <List>
          {table.relations.map((relation, index) => {
            return (
              <React.Fragment>
                <ListItem
                  key={relation.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={!isEditorOrOwner()}
                      onClick={() => handledelete(relation.id as number)}
                    >
                      <MdDelete />
                    </IconButton>
                  }
                >
                  <div className="relation-list-content">
                    <div className="relation-list-content-item">
                      <ListItemAvatar>
                        <MdViewColumn />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Parent column"
                        secondary={relation.parentColumnName}
                      />
                    </div>
                    <div className="relation-list-content-item">
                      <ListItemAvatar>
                        {/* <Avatar> */}
                        <MdTableView />
                        {/* </Avatar> */}
                      </ListItemAvatar>
                      <ListItemText
                        primary="Child table"
                        secondary={getChildTable(relation.childTableId)?.name}
                      />
                    </div>
                    <div className="relation-list-content-item">
                      <ListItemAvatar>
                        {/* <Avatar> */}
                        <MdViewColumn />
                        {/* </Avatar> */}
                      </ListItemAvatar>
                      <ListItemText
                        primary="Child column"
                        secondary={relation.childColumnName}
                      />
                    </div>
                  </div>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      ) : (
        <p>No relations found</p>
      )}
    </div>
  );
};

export default RelationsList;
