import React, { ErrorInfo } from 'react';

type ErrorBoundaryProps = {
   
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = () => {

    return <div>
        <h1>Oops! Something went wrong.</h1>
    </div>
};

export default ErrorBoundary;