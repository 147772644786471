import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APP_TYPES, TApp } from "../models";
import { revertAll } from ".";

// todo reset store on logout
interface AppState {
  isLoaded: boolean;
  apps: TApp[];
  appType: APP_TYPES;
  isErrorLoading: boolean;
}
interface TAppSliceState {
  [APP_TYPES.OWN]: AppState;
  [APP_TYPES.SHARED]: AppState;
}

interface TAppPayload {
  appType: APP_TYPES;
  apps: TApp[];
}

const initialState: TAppSliceState = {
  [APP_TYPES.OWN]: {
    isLoaded: false,
    apps: [],
    appType: APP_TYPES.OWN,
    isErrorLoading: false,
  },
  [APP_TYPES.SHARED]: {
    isLoaded: false,
    apps: [],
    appType: APP_TYPES.SHARED,
    isErrorLoading: false,
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(revertAll, () => initialState);
  },
  reducers: {
    setApps: (state, action: PayloadAction<TAppPayload>) => {
      const { appType, apps } = action.payload;
      state[appType].apps = apps;
      state[appType].isLoaded = true;
      state[appType].isErrorLoading = false;
    },
    setErrorLoading: (state, action: PayloadAction<APP_TYPES>) => {
      state[action.payload].isErrorLoading = true;
      state[action.payload].isLoaded = true;
      state[action.payload].apps = [];
    },
    addApp: (
      state,
      action: PayloadAction<{ appType: APP_TYPES; app: TApp }>
    ) => {
      const { appType, app } = action.payload;
      state[appType].apps.push(app);
    },
    removeAppWithAppType: (
      state,
      action: PayloadAction<{ appType: APP_TYPES; appId: number }>
    ) => {
      const { appType, appId } = action.payload;
      state[appType].apps = state[appType].apps.filter(
        (app) => app.id !== appId
      );
    },

    // Add more reducers as needed
  },
});

export const { setApps, setErrorLoading, addApp, removeAppWithAppType } =
  appSlice.actions;

export default appSlice.reducer;
