import { Outlet, useNavigate } from 'react-router-dom';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Navigation from './screens/Navigation/Navigation';
import { auth } from './firebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoginError, setAuthState, setLoginError, setUserLoggedIn } from './store/authReducer';
import { useEffect } from 'react';
import LoaderOverlay from './components/Loader';
import { RootState } from './store';
import { userLogin } from './apis/services';
import { resetLoading, setLoading } from './store/loaderReducer';



function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.loader);

  const checkIfuserRegistered = async () => {
    try{
      dispatch(setLoading());
      dispatch(resetLoginError());
      const response = await userLogin();
      console.log(response);
      dispatch(setUserLoggedIn(true))
      return true;
    }catch(e){
      dispatch(setLoginError('Something went wrong. Please try again'));
      return false;
    }finally{
      dispatch(resetLoading());
    }
  }
  const navigateToDashboard = () => {
    navigate('/dashboard');
  }
  // method to check if current user exists
  const checkUser = () => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(setAuthState(user));
      if (user) {
        console.log('User is signed in');
        const isLoggedIn = await checkIfuserRegistered();
        isLoggedIn && navigateToDashboard();
      } else {
        console.log('User is signed out');
        dispatch(setUserLoggedIn(false));
      }
    });
  }

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <div className='app'>
      <Navigation />
      <div className='app-outlet'>
        <Outlet />
      </div>
      <LoaderOverlay loading={isLoading} />
    </div>
  );
}

export default App;
