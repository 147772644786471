import axios from "axios";
import { store } from "../store";

export const api = axios.create({
    baseURL: "https://api.xlsahi.ai",
});


// code to set header before every call
api.interceptors.request.use(async (config) => {
    const user = store.getState().auth.user;
    if (user) {
        const token = await user?.getIdToken();
        config.headers["auth-token"] =token;
        config.headers["email"] = user?.email;
        config.headers["uid"] = user?.uid;
    }
    return config;
});