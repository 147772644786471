import MenuSection from "./MenuSection";
import { APP_TYPES, TApp } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setApps, setErrorLoading } from "../../store/appReducer";
import { fetchOwnApps, fetchSharedApps } from "../../apis/services";
import { useEffect, useState } from "react";
import { FaFolderPlus } from "react-icons/fa";
import { ActionButton } from "../../components/CustomButtons";
import CreateApp from "../CreateApp";
import { setCurrentApp } from "../../store/selectedAppReducer";

const LeftMenu = () => {
  const apps = useSelector((state: RootState) => state.apps);
  const [showCreateApp, setShowCreateApp] = useState(false);
  const dispatch = useDispatch();

  const loadOwnAppData = async () => {
    let ownApps = [];
    try {
      const response = await fetchOwnApps();
      const data = response.data;
      dispatch(setApps({ appType: APP_TYPES.OWN, apps: data }));
      if (data.length > 0) {
        dispatch(setCurrentApp({app: data[0], type: APP_TYPES.OWN}));
      }
      ownApps = data;
    } catch (e) {
      dispatch(setErrorLoading(APP_TYPES.OWN));
    } finally {
    }
    return ownApps;
  };

  const loadSharedAppData = async (ownApps: TApp[]) => {
    try {
      const response = await fetchSharedApps();
      const data = response.data;
      dispatch(setApps({ appType: APP_TYPES.SHARED, apps: data }));
      // sets shared app as current app if no app is selected
      if(ownApps.length===0 && data.length > 0) {
        dispatch(setCurrentApp({app: data[0], type: APP_TYPES.SHARED}));
      }
    } catch (e) {
      dispatch(setErrorLoading(APP_TYPES.SHARED));
    } finally {
    }
  };
  const loadData = async () => {
   const ownApps =  await loadOwnAppData();
    await loadSharedAppData(ownApps);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="left-menu">
      <ActionButton
        variant="contained"
        color="primary"
        onClick={() => setShowCreateApp(true)}
        startIcon={<FaFolderPlus />}
      >
        Create New App
      </ActionButton>
      <CreateApp
        show={showCreateApp}
        handleClose={() => setShowCreateApp(false)}
      />
      <div className="menu-section-wrapper">
        <MenuSection
          isLoaded={apps[APP_TYPES.OWN].isLoaded}
          apps={apps[APP_TYPES.OWN].apps}
          appType={APP_TYPES.OWN}
          isErrored={apps[APP_TYPES.OWN].isErrorLoading}
        />
        <MenuSection
          isLoaded={apps[APP_TYPES.SHARED].isLoaded}
          apps={apps[APP_TYPES.SHARED].apps}
          appType={APP_TYPES.SHARED}
          isErrored={apps[APP_TYPES.SHARED].isErrorLoading}
        />
      </div>
    </div>
  );
};

export default LeftMenu;
